import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`API References by Package`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/api/plugin"
        }}>{`@pluginsdotdev/plugin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/api/react-host"
        }}>{`@pluginsdotdev/react-host`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      